<template>
  <HxTable v-model:columns="columns" :reload="loadData">
    <!-- 页面头部查询模块 -->
    <template #search>
      <a-row :gutter="[24, 24]" justify="end">
        <a-col :span="6" class="search">
          <a-form-item label="">
            <a-range-picker
              v-model:value="searchReset.time"
              @change="timeChange"
              :show-time="{ format: 'HH:mm' }"
              format="YYYY-MM-DD"
              valueFormat="YYYY-MM-DD"
              placeholder="创建时间"
              style="width: 100%"
            />
          </a-form-item>
        </a-col>
        <a-col :span="4" class="search">
          <a-form-item label="">
            <a-cascader
              v-model:value="searchReset.ChannelTypeEnum"
              :options="$store.state.enumAll.ChannelTypeEnum"
              :show-search="{ filter }"
              :changeOnSelect="true"
              @change="setChannel"
              placeholder="渠道来源"
            />
          </a-form-item>
        </a-col>
        <a-col :span="4" class="search">
          <a-form-item label="">
            <a-select
              allowClear
              v-model:value="searchForm.clueType"
              class="ransport_search_input"
              placeholder="线索分类"
              :options="$store.state.enumAll.ClueTypeEnum"
            >
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="4" class="search">
          <a-form-item label="">
            <a-select
              allowClear
              v-model:value="searchForm.isFall"
              placeholder="是否掉落"
              class="ransport_search_input"
              :options="$store.state.enumAll.BooleanFlagEnum"
            >
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-space>
            <a-button
              :loading="searchLoading"
              type="primary"
              @click="onSearch"
              :disabled="searchLoading"
            >
              查询
            </a-button>
            <a-button @click="reset"> 重置 </a-button>
          </a-space>
        </a-col>
      </a-row>
    </template>
    <template #totalbar>
      <ExclamationCircleTwoTone twoToneColor="#427bb1" /> 你当天领取总数：
      <a-tag color="#1890ff">{{ addTotalNum.highSeasTotalNum }}</a-tag> 条，已领
      <a-tag color="#1890ff">{{ addTotalNum.highSeasNum }}</a-tag> 条
    </template>
    <template #toolReload> </template>
    <!-- 上方的左边按钮 -->
    <template #toolbarLeft>
      <a-space>
        <a-button @click="onOneClickReceive" class="tool-color-344563">
          <icon-font type="hy-lingqu1" class="tool-icon" />
          一键领取
        </a-button>
        <a-button
          :disabled="searchReceive"
          @click="searchReceiveShow = true"
          class="tool-color-344563"
        >
          <icon-font type="hy-lingqu1" class="tool-icon" />
          领取客户
        </a-button>

        <!-- <a-button @click="searchAddShow=true" class="tool-color-344563">
          <icon-font type="hy-tianjia" class="tool-icon" /> 新增线索
        </a-button> -->
        <a-button @click="searchPhoneShow = true" class="tool-color-344563">
          <icon-font type="hy-dianhua" class="tool-icon" />
          领取主叫客户
        </a-button>
        <a-button
          :disabled="searchReceive"
          @click="mandatory = true"
          class="tool-color-344563"
        >
          <RetweetOutlined />
          强制分配
        </a-button>
      </a-space>
      <a-modal
        v-model:visible="mandatory"
        width="40%"
        title="强制分配"
        @ok="distribution"
      >
        <div>选择负责人<span style="color: #f00">*</span></div>
        <a-select
          style="width: 100%"
          mode="multiple"
          :not-found-content="fild ? undefined : null"
          :filter-option="false"
          show-search
          placeholder="请输入员工姓名或关键字"
          @inputKeyDown="search"
          @search="assignment"
          @change="memberChange"
        >
          <a-select-option v-for="item in options" :key="item.id" :value="item.name"
            >{{ item.name }}-{{ item.no }}</a-select-option
          >
          <template v-if="fild" #notFoundContent>
            <a-spin size="small" />
          </template>
        </a-select>
        <span style="color: #f00; font-size: 14px">在输入完成后，按下回车搜索</span>
      </a-modal>
      <a-modal
        v-model:visible="searchReceiveShow"
        width="40%"
        title="领取客户"
        @ok="onSearchReceive"
      >
        <div class="search-receive-model">
          <div class="search-receive-model-left">
            <ExclamationCircleTwoTone
              twoToneColor="#FFAB00"
              class="search-receive-model-left-lcon"
            />
          </div>
          <div class="search-receive-model-right">
            <p>确定要领取该客户吗？</p>
            <p class="search-receive-model-right-shallow">
              今日还可以领取{{ addTotalNum.highSeasSurplusNum }}条
            </p>
          </div>
        </div>
      </a-modal>
      <a-modal v-model:visible="searchAddShow" width="30%" @ok="onAddForm">
        <template #title>
          <span class="title">新增</span>
        </template>
        <div>
          <a-form layout="vertical" ref="formRef" :model="addForm" :rules="rules">
            <a-form-item label="客户电话" name="phoneNumber">
              <a-input v-model:value="addForm.phoneNumber" placeholder="填写电话" />
            </a-form-item>
            <a-form-item label="客户微信">
              <a-input v-model:value="addForm.wechat" placeholder="填写客户微信" />
            </a-form-item>
            <a-form-item label="客户报价">
              <a-input v-model:value="addForm.totalPrice" placeholder="填写客户报价" />
            </a-form-item>
            <a-form-item label="起始地点">
              <div style="display: flex; justify-content: space-around">
                <a-cascader
                  style="width: 45%"
                  v-model:value="start"
                  :options="$store.state.app.cityDict"
                  :changeOnSelect="true"
                  @change="setStartAddress"
                  placeholder="选择起始地"
                />
                <div>——</div>
                <a-cascader
                  style="width: 45%"
                  v-model:value="end"
                  :options="$store.state.app.cityDict"
                  :changeOnSelect="true"
                  @change="setEndAddress"
                  placeholder="选择目的地"
                />
              </div>
            </a-form-item>
            <!-- <a-form-item label="目的地点">
            </a-form-item> -->
            <!-- <a-form-item label="线索类型">
              <a-select allowClear v-model:value="addForm.clueTypeEnum" class="ransport_search_input">
                <a-select-option v-for="item in $store.state.enumAll.ClueTypeEnum" :key="item.value"> {{ item.label }}</a-select-option>
              </a-select>
            </a-form-item> -->
            <a-form-item label="渠道来源" name="channelSubIds">
              <a-cascader
                v-model:value="addForm.channelSubIds"
                :options="$store.state.enumAll.ChannelTypeEnum"
                :show-search="{ filter }"
                :changeOnSelect="true"
                placeholder="选择渠道"
              />
            </a-form-item>
            <!-- <a-form-item label="渠道网址">
              <a-input v-model:value="addForm.url" />
            </a-form-item> -->
            <a-form-item>
              <template #label>
                <div>
                  <span>客户标签 </span>
                  <a style="font-size: 12px" @click="addForm.tagId = ''"> 重置选择</a>
                </div>
              </template>
              <div>
                <a-radio-group v-model:value="addForm.tagId" button-style="solid">
                  <a-space>
                    <a-radio-button
                      v-for="item in $store.state.enumAll.CustomerLabelType"
                      :key="item.value"
                      :value="item.value"
                      >{{ item.label }}</a-radio-button
                    >
                  </a-space>
                </a-radio-group>
              </div>
            </a-form-item>
            <a-form-item label="备注">
              <a-input placeholder="填写备注" v-model:value="addForm.remark" />
            </a-form-item>
          </a-form>
        </div>
      </a-modal>
      <a-modal v-model:visible="searchPhoneShow" width="30%" @ok="onPhone">
        <template #title>
          <span class="title">领取主叫客户</span>
        </template>
        <a-form layout="vertical" :model="formState">
          <a-form-item label="请输入客户手机号">
            <a-input v-model:value="phone" />
          </a-form-item>
        </a-form>
      </a-modal>
    </template>
    <!-- 表格主体 -->
    <a-table
      size="small"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
        type: 'radio',
      }"
      :columns="columns"
      :row-key="(record) => record.customerId"
      :data-source="listData"
      bordered
      :pagination="pagination"
      :loading="searchLoading"
      @change="handleTableChange"
    >
      <template #channel="{ record }">
        {{ record.channelLabel
        }}<span v-if="record.channelLabel != null && record.channelSubLabel != null"
          >-{{ record.channelSubLabel }}</span
        >
      </template>
      <template #mobile="{ record }">
        <span v-if="record.mobile !== null && record.mobile !== ''">{{
          record.mobile
        }}</span>
        <span v-else>{{ record.wechat }}</span>
      </template>
      <template #operation="{ record }">
        <span>
          <a @click="onSee(record)">查看</a>
        </span>
      </template>
    </a-table>
    <a-drawer
      title="流转记录"
      width="760"
      placement="right"
      v-model:visible="followShow"
      destroyOnClose
    >
      <circulation ref="recodeCir" v-model:customerId="customerId" />
    </a-drawer>
  </HxTable>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from 'vue'
import HxTable from '@/components/HxTable'

import { mobileValidate } from '@/utils/validate'
import {
  ExclamationCircleTwoTone,
  createFromIconfontCN,
  RetweetOutlined
} from '@ant-design/icons-vue'
import {
  page,
  call,
  receive,
  addPublic,
  allocate,
  receiveOneClick
} from '@/api/crmManagement/comSea'
import { addTotal } from '@/api/crmManagement/tool'
import circulation from '../tool/circulation/index.vue'
import { employeeList } from '@/api/crmManagement/setRules'
import { message } from 'ant-design-vue'
const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_2734322_olh9vocrojb.js'
})
export default {
  setup () {
    const formRef = ref()
    const state = reactive({
      recodeCir: ref(),
      start: '',
      end: '',
      empId: '',
      fild: false,
      mandatory: false,
      timer: undefined,
      fileList: [],
      options: [],
      empty: {},
      searchReset: {},
      addTotalNum: {
        highSeasTotalNum: 0,
        highSeasNum: 0
      },
      phone: '',
      searchForm: {
        channel: 0
      },
      customerId: '',
      addForm: {
        wechat: '',
        phoneNumber: '',
        startAddress: {},
        endAddress: {}
      },
      selectedRowKeys: [],
      searchLoading: false,
      searchReceive: true,
      searchPhoneShow: false,
      searchReceiveShow: false,
      searchAddShow: false,
      followShow: false,
      columns: [
        {
          title: '客户电话/微信',
          dataIndex: 'mobile',
          width: '7%',
          slots: {
            customRender: 'mobile'
          }
        },
        {
          title: '线索分类',
          dataIndex: 'clueType.label',
          width: '5%'
        },
        // {
        //   title: '渠道来源',
        //   dataIndex: 'channel',
        //   width: '11%',
        //   slots: {
        //     customRender: 'channel'
        //   }
        // },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          width: '8%'
        },
        // {
        //   title: '前负责人',
        //   dataIndex: 'faller',
        //   width: '6%'
        // },
        {
          title: '掉落时间',
          dataIndex: 'updateTime',
          width: '8%'
        },
        // {
        //   title: 'IP归属地',
        //   dataIndex: 'ipAddress'
        // },
        {
          title: '掉落原因',
          dataIndex: 'publicRemark'
        },
        {
          title: '流转记录',
          dataIndex: 'operation',
          width: 100,
          fixed: 'right',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      rules: {
        phoneNumber: [
          { required: false, message: '请填写电话号码', trigger: 'blur' },
          { pattern: mobileValidate, message: '请填写正确的电话号码', trigger: 'blur' }
        ],
        channelSubIds: {
          required: true,
          message: '请选择渠道来源',
          trigger: 'change',
          type: 'array'
        }
      },
      listData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    // 重置
    const reset = () => {
      state.searchForm = {}
      state.searchReset = {}
      loadData()
    }
    // 查询
    const onSearch = () => {
      state.searchLoading = true
      state.pagination.current = 1
      loadData()
    }
    // 高级搜索方法
    const onSearchSenior = () => {
      state.searchLoading = true
      state.pagination.current = 1
      loadData()
    }
    /**
     * 每次提交检查渠道来源数据项
     */
    const setChannels = () => {
      if (state.searchReset.ChannelTypeEnum) {
        state.searchForm.channelId = state.searchReset.ChannelTypeEnum[0]
        if (state.searchReset.ChannelTypeEnum.length > 1) {
          state.searchForm.channelSubId = state.searchReset.ChannelTypeEnum[1]
        } else {
          state.searchForm.channelSubId = undefined
        }
      } else {
        state.searchForm.channelId = undefined
        state.searchForm.channelSubId = undefined
      }
    }
    const loadData = () => {
      state.searchLoading = true
      setChannels()
      page({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      })
        .then((res) => {
          if (res.code === 10000) {
            state.listData = res.data.records
            state.pagination.current = res.data.current
            state.pagination.pageSize = res.data.size
            state.pagination.total = res.data.total
          }
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          state.searchLoading = false
        })
      addTotalMethod()
    }
    // 表格数据页码改变事件
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    const onSelectChange = (selectedRowKeys) => {
      state.selectedRowKeys = selectedRowKeys
      if (selectedRowKeys.length > 0) {
        state.searchReceive = false
      } else {
        state.searchReceive = true
      }
    }
    // 渠道来源子类赋值
    const onChannel = (e) => {
      state.searchForm.children = e
    }
    // 领取客户
    const onSearchReceive = () => {
      if (state.selectedRowKeys[0] !== '') {
        receive(state.selectedRowKeys[0]).then((res) => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.searchReceiveShow = false
            loadData()
          }
        })
      } else {
        message.error('客户id为null')
      }
    }
    // 一键领取
    const onOneClickReceive = () => {
      receiveOneClick({}).then((res) => {
        if (res.code === 10000) {
          message.success(res.msg)
          loadData()
        } else if (res.code === 10001) {
          loadData()
        }
      })
    }
    // 新增
    const onAddForm = () => {
      if (state.addForm.phoneNumber !== '' || state.addForm.wechat !== '') {
        formRef.value
          .validate()
          .then(() => {
            const channelArr = state.addForm.channelSubIds
            state.addForm.channelId = channelArr[0]
            state.addForm.channelSubId = channelArr[1]
            delete state.addForm.channelSubIds
            if (!state.addForm.endAddress.province) state.addForm.endAddress = null
            if (!state.addForm.startAddress.province) state.addForm.startAddress = null
            addPublic(state.addForm).then((res) => {
              if (res.code === 10000) {
                message.success(res.msg)
                delete state.addForm.channelSubIds
                loadData()
                state.searchAddShow = false
                state.start = ''
                state.end = ''
                state.addForm = state.empty
                state.empty = JSON.parse(JSON.stringify(state.addForm))
              }
            })
          })
          .catch((err) => {
            console.log(err)
          })
      } else message.error('电话和微信号必须填写其中一个')
    }
    // 每日剩余数量
    const addTotalMethod = () => {
      addTotal().then((res) => {
        if (res.code === 10000) {
          state.addTotalNum = res.data
          state.addTotalNum.highSeasSurplusNum =
            state.addTotalNum.highSeasTotalNum - state.addTotalNum.highSeasNum
        }
      })
    }
    // 查看流转
    const onSee = (e) => {
      state.customerId = e.customerId
      state.followShow = true
    }
    // 主叫领取客户
    const onPhone = () => {
      call({ phone: state.phone }).then((res) => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.searchPhoneShow = false
          loadData()
        }
      })
    }

    const setChannel = (value, selectedOptions) => {
      state.searchForm.channelId = value[0]
      if (value.length > 1) {
        state.searchForm.channelSubId = value[1]
      }
    }
    const setStartAddress = (value, selectedOptions) => {
      state.addForm.startAddress.province = value[0]
      if (value.length > 1) {
        state.addForm.startAddress.city = value[1]
      } else {
        state.addForm.startAddress.city = null
      }
      if (value.length > 2) {
        state.addForm.startAddress.area = value[2]
      } else {
        state.addForm.startAddress.area = null
      }
    }

    const setEndAddress = (value, selectedOptions) => {
      state.addForm.endAddress.province = value[0]
      if (value.length > 1) {
        state.addForm.endAddress.city = value[1]
      } else {
        state.addForm.endAddress.city = null
      }
      if (value.length > 2) {
        state.addForm.endAddress.area = value[2]
      } else {
        state.addForm.endAddress.area = null
      }
    }

    const timeChange = (e, v) => {
      state.searchForm.startTime = v[0]
      state.searchForm.endTime = v[1]
    }
    const search = (e) => {
      if (e.keyCode === 13) {
        state.fild = true
        state.timer = setTimeout(() => {
          employeeList({
            current: 1,
            name: state.name,
            size: 10
          })
            .then((res) => {
              if (res.code === 10000) {
                state.options = res.data
                state.fild = false
              }
            })
            .catch((err) => {
              console.log(err)
            })
        }, 500)
      }
    }
    const assignment = (e) => {
      if (e !== '') state.name = e
    }
    const memberChange = (e, val) => {
      if (e.length > 1) {
        e.splice(0, 1)
        val.splice(0, 1)
        e[0] = val[0].value
      }
      if (val.length > 0) {
        state.empId = val[0].key
      }
      state.options = []
    }
    // 强制分配
    const distribution = () => {
      state.mandatory = false
      allocate({
        customerId: state.selectedRowKeys[0],
        empId: state.empId
      })
        .then((res) => {
          if (res.code === 10000) {
            loadData()
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
    onMounted(() => {
      loadData()
      state.empty = JSON.parse(JSON.stringify(state.addForm))
    })
    const beforeUpload = (info) => {
      return false
    }
    const handleChange = async (info) => {
      await state.fileList.push(info.file)
      await console.log(info.file)
      await console.log(state.fileList)
    }
    return {
      ...toRefs(state),
      onSearch,
      reset,
      formRef,
      beforeUpload,
      handleChange,
      setStartAddress,
      setEndAddress,
      loadData,
      handleTableChange,
      onSelectChange,
      onSearchSenior,
      onChannel,
      onSearchReceive,
      onOneClickReceive,
      onAddForm,
      onSee,
      setChannel,
      onPhone,
      addTotalMethod,
      timeChange,
      search,
      assignment,
      memberChange,
      distribution
    }
  },
  components: {
    HxTable,
    ExclamationCircleTwoTone,
    IconFont,
    RetweetOutlined,
    circulation
  }
}
</script>
<style lang="less" scoped>
@import "../tool/index.less";
@import "./index.less";

:deep(.ant-upload-list-item-error) {
  color: #000 !important;
}
:deep(.anticon-paper-clip) {
  color: #000 !important;
}
:deep(.anticon-delete) {
  color: #000 !important;
}
:deep(.ant-upload-list-item-name) {
  color: #000 !important;
}
</style>
